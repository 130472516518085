


















































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import { BSpinner } from "bootstrap-vue";
import { mapState } from "vuex";
import { AcceptPackageDTO, Package } from "@/modules/genprox/models/Package";
import { UserContext } from '@/modules/genprox/models/User';
import jsPDF from 'jspdf';
import GenproxPageHeading from '@/components/layout/GenproxPageHeading.vue';
import GenproxFormBar from '@/components/layout/GenproxFormBar.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import GenproxButton from '@/components/buttons/GenproxButton.vue';
import ChevronLeftIcon from '@/assets/icons/heroicons/ChevronLeftIcon.vue';
import CheckCircleIcon from '@/assets/icons/heroicons/CheckCircleIcon.vue';

@Component({
  components: { SygniRoundedButton, SygniRectButton, SygniCheckbox, BSpinner, GenproxPageHeading, GenproxFormBar, SygniLoader, GenproxButton, ChevronLeftIcon, CheckCircleIcon },
  computed: {
    ...mapState('genprox', {
      packages: (state: any) => state.packages,
      activeUserContext: (state: any) => state.activeUserData.context
    })
  },
})
export default class Welcome extends Vue {
  ChevronLeftIcon = ChevronLeftIcon;
  CheckCircleIcon = CheckCircleIcon;

  packages!: Array<Package>;
  packageId = this.$route.params.id;
  regulation: string = null;
  areRegulationsAccepted: boolean = false;
  rightSideHeight: number = 0;
  rightSideContentHeight: number = 0;
  activeUserContext!: UserContext;

  get package(): Package {
    return this.packages?.find(pack => pack.id === this.packageId);
  }

  get paymentPlan(): string {

    if (this.package?.plan === 'One-off') {
      return 'one-time payment'
    }

    switch (this.package?.billingPeriod) {
      case "Annually":
        return "annually";
      case "Monthly":
        return "monthly";
      case "Quarterly":
        return "quarterly";
      default:
        return '';
    }
  }

  get activeUserDataContext() {
    return this.$store.getters['genprox/activeUserData']?.context?.context;
  }

  goToRegReporting() {
    if (!this.package?.active) return

    const parentPath = this.activeUserDataContext === 'fund-manager' ? 'fund-manager' : 'fund'
    this.$router.push(`/${parentPath}/regulatory-reporting`)
  }

  async mounted() {
    try {
      this.regulation = await this.$store.dispatch(`fund/getKNFReportAgreement`, this.packageId);
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
      this.$router.push('/welcome')
    }
  }

  async redirectToModule(module: string) {
    const { context } = this.activeUserContext;

    // to do: refresh menu
    try {
      await this.$store.dispatch('genprox/getMenus')
    } catch (e) {
      console.log(e);
    }

    if (context === 'fund') {
      if (module === 'whistleblower') {
        await this.$router.push('/fund/whistleblower');
      }
      if (module === 'regulatoryReporting') {
        await this.$router.push('/fund/regulatory-reporting');
      }
    } else if (context === 'fund-manager') {
      if (module === 'whistleblower') {
        await this.$router.push('/fund/whistleblower');
      }
      if (module === 'regulatoryReporting') {
        await this.$router.push('/fund-manager/regulatory-reporting');
      }
    } else if (context === 'company') {
      if (module === 'whistleblower') {
        await this.$router.push('/fund/whistleblower');
      }
    } else {
      await this.$router.push({ name: 'welcome' });
    }
  }

  async accept(): Promise<void> {
    if (!this.package.active) {
      const acceptPackageDTO: AcceptPackageDTO = { packageId: this.packageId };
      await this.$store.dispatch('genprox/activePackage', acceptPackageDTO);

      for (const key in this.package.accessModules) {
        if (this.package.accessModules[key]) {
          await this.redirectToModule(key);
          return;
        }
      }
    }
  }

  goToInsight() {
    this.$router.push('/insights/odpowiedz-zasi-na-wezwanie-knf-ws-esg')
  }

  wrapUncontainedTextWithSpan(nodes: NodeList[]) {
    function wrapNode(node: any, wrapper: any) {
      node.parentNode.insertBefore(wrapper, node);
      wrapper.appendChild(node);
    }
    function wrapTextContent(node: any) {
      if (node.nodeType !== Node.TEXT_NODE) {
        return;
      }
      if (node.textContent.trim() === 0) {
        return;
      }
      const span = document.createElement("span");
      wrapNode(node, span);
    }

    nodes.forEach(function wrapEachTextContent(node: any) {
      node.childNodes.forEach(wrapTextContent);
    });
  }

  unwrap (wrapper: any) {
    wrapper.replaceWith(...wrapper.childNodes)
  }

  async generatePdf() {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: 'a4',
      putOnlyUsedFonts: true,
      floatPrecision: 16,
    }).setDisplayMode('fullwidth');

    const content: any = document.createElement('div');
    content.classList.add('wysiwyg-editor-pdf');
    content.innerHTML = this.regulation;

    const style = document.createElement('style');
    style.innerHTML = `
      .wysiwyg-editor-pdf h1, .wysiwyg-editor-pdf h2, .wysiwyg-editor-pdf h3, .wysiwyg-editor-pdf h4, .wysiwyg-editor-pdf h5, .wysiwyg-editor-pdf h6 {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .wysiwyg-editor-pdf ul h1, .wysiwyg-editor-pdf ul h2, .wysiwyg-editor-pdf ul h3, .wysiwyg-editor-pdf ul h4, .wysiwyg-editor-pdf ul h5, .wysiwyg-editor-pdf ul h6,
      .wysiwyg-editor-pdf ol h1, .wysiwyg-editor-pdf ol h2, .wysiwyg-editor-pdf ol h3, .wysiwyg-editor-pdf ol h4, .wysiwyg-editor-pdf ol h5, .wysiwyg-editor-pdf ol h6 {
        margin-bottom: 7px;
        margin-top: 7px;
      }

      .wysiwyg-editor-pdf p {
        margin-bottom: 10px;
      }

      .wysiwyg-editor-pdf ul p, .wysiwyg-editor-pdf ol p {
        margin-bottom: 0;
      }

      .wysiwyg-editor-pdf ul {
        list-style-type: none;
      }

      .wysiwyg-editor-pdf > ul, .wysiwyg-editor-pdf > ol {
        margin-bottom: 20px;
      }

      .wysiwyg-editor-pdf ul > li {
        display: table;
        margin-bottom: 0;
        width: 100%;
      }

      .wysiwyg-editor-pdf ul > li:before {
        content: "\\2022";
        padding-right: 15px;
        display: table-cell;
        min-width: 25px;
        width: 1px;
      }

      .wysiwyg-editor-pdf ol {
        list-style-type: none;
        counter-reset: item;
      }

      .wysiwyg-editor-pdf ol > li {
        display: table;
        counter-increment: item;
        width: 100%;
      }

      .wysiwyg-editor-pdf ol > li:before {
        content: counters(item, ".") ". ";
        min-width: 25px !important;
        width: 1px !important;
        padding-right: 15px;
        display: table-cell;
      }

      .wysiwyg-editor-pdf li ol > li:before {
        content: counters(item, ".") ". ";
      }

      .wysiwyg-editor-pdf strong,
      .wysiwyg-editor-pdf b {
        font-weight: 700;
      }

      .wysiwyg-editor-pdf em,
      .wysiwyg-editor-pdf i {
        font-style: italic;
      }

      .wysiwyg-editor-pdf a:not([class*='btn-']) {
        text-decoration: underline;
      }

      .wysiwyg-editor-pdf img {
        width: auto;
        max-width: 220px;
        height: auto;
        object-fit: contain;
        margin: 0 auto;
        display: block;
      }

      .wysiwyg-editor-pdf blockquote {
        margin: 30px auto;
        width: 67%;
      }

      .wysiwyg-editor-pdf ul + h1, .wysiwyg-editor-pdf ul + h2, .wysiwyg-editor-pdf ul + h3, .wysiwyg-editor-pdf ul + h4, .wysiwyg-editor-pdf ul + h5, .wysiwyg-editor-pdf ul + h6,
      .wysiwyg-editor-pdf ol + h1, .wysiwyg-editor-pdf ol + h2, .wysiwyg-editor-pdf ol + h3, .wysiwyg-editor-pdf ol + h4, .wysiwyg-editor-pdf ol + h5, .wysiwyg-editor-pdf ol + h6 {
        margin-top: 30px;
      } 
    `;

    document.head.appendChild(style);

    content.style.fontSize = '0';
    content.style.wordWrap = 'break-word';

    content.innerHTML = content.innerHTML.replace(/(\r\n|\n|\r)/gm, "");

    const ul = content.querySelectorAll('ul');
    const ol = content.querySelectorAll('ol');
    const uli = content.querySelectorAll('ul li');
    const oli = content.querySelectorAll('ol li');
    const tables = content.querySelectorAll('table');
    const customParagraphs = content.querySelectorAll('div[data-type="custom-section-node"]');
    const hyperlinks = content.querySelectorAll('a');

    hyperlinks.forEach((link: any) => {
      const el = document.createElement('span')
      el.textContent = link.textContent
      link.parentNode.replaceChild(el, link)
    })

    this.wrapUncontainedTextWithSpan(uli);
    this.wrapUncontainedTextWithSpan(oli);

    uli.forEach((el: HTMLElement) => {
      el.style.position = 'relative';
      el.style.lineHeight = '1.5';
      el.style.fontSize = '8px';

      el.innerHTML = `<p>${el.innerHTML}</p>`;

      el.querySelectorAll('p').forEach((p: HTMLElement) => {
        p.style.position = 'relative';
        p.style.paddingTop = '1.5px';
        p.style.paddingBottom = '0.45px';
      })
    })

    oli.forEach((el: HTMLElement) => {
      el.style.position = 'relative';
      el.style.lineHeight = '1.5';
      el.style.fontSize = '8px';

      el.innerHTML = `<p>${el.innerHTML}</p>`;

      el.querySelectorAll('p').forEach((p: HTMLElement) => {
        p.style.position = 'relative';
        p.style.paddingTop = '1.5px';
        p.style.paddingBottom = '0.45px';
      })
    })

    this.wrapUncontainedTextWithSpan(content.querySelectorAll('strong'))
    this.wrapUncontainedTextWithSpan(content.querySelectorAll('del'))
    this.wrapUncontainedTextWithSpan(content.querySelectorAll('em'))
    this.wrapUncontainedTextWithSpan(content.querySelectorAll('b'))
    this.wrapUncontainedTextWithSpan(content.querySelectorAll('i'))
    this.wrapUncontainedTextWithSpan(content.querySelectorAll('u'))
    this.wrapUncontainedTextWithSpan(content.querySelectorAll('s'))

    const paragraphs = content.querySelectorAll('p');
    this.wrapUncontainedTextWithSpan(paragraphs);

    paragraphs.forEach((p: HTMLParagraphElement) => {
      p.style.lineHeight = '1.5';
      p.style.display = 'block';
      p.style.fontSize = '8px';
    })

    new Set([...ul, ...ol]).forEach((el: HTMLElement) => {
      el.style.position = 'relative';
      el.style.marginBottom = '8px';
      el.style.paddingLeft = '10px';

      el.querySelectorAll('p').forEach((div: HTMLDivElement) => {
        div.style.color = '#38303b';
      })
    })

    new Set([...content.querySelectorAll('ul ul'), ...content.querySelectorAll('ul ol'), ...content.querySelectorAll('ol ul'), ...content.querySelectorAll('ol ol')]).forEach((el: HTMLElement) => {
      el.style.paddingLeft = '10px';
      el.style.marginBottom = '8px';
    })

    new Set([...content.querySelectorAll('ul li > ul'), ...content.querySelectorAll('ul li > ol'), ...content.querySelectorAll('ol li > ul'), ...content.querySelectorAll('ol li > ol')]).forEach((el: HTMLElement) => {
      el.style.marginTop = '8px';
    })

    this.wrapUncontainedTextWithSpan(content.querySelectorAll('ul li > span'))
    this.wrapUncontainedTextWithSpan(content.querySelectorAll('ol li > span'))

    const counters = content.querySelectorAll('.counter');

    counters.forEach((counter: HTMLSpanElement) => {

      counter.style.position = 'absolute';
      counter.style.right = 'calc(100% + 3px)';
      counter.style.top = '1.5px';
      counter.style.textAlign = 'right';
    });

    const headings1 = content.querySelectorAll('h1');
    const headings2 = content.querySelectorAll('h2');
    const headings3 = content.querySelectorAll('h3');
    const headings4 = content.querySelectorAll('h4');
    const headings5 = content.querySelectorAll('h5');
    const headings6 = content.querySelectorAll('h6');

    new Set([...headings1, ...headings2]).forEach((h1: HTMLSpanElement) => {
      h1.style.fontSize = '12px';
      h1.style.fontWeight = '700';
      h1.style.display = 'block';
    })

    headings2.forEach((h2: HTMLSpanElement) => {
      h2.style.fontSize = '11px';
    })

    new Set([...headings3, ...headings4, ...headings5, ...headings6]).forEach((h3: HTMLSpanElement) => {
      h3.style.fontSize = '10px';
      h3.style.fontWeight = '700';
      h3.style.display = 'block';
    })

    tables.forEach((table: HTMLTableElement) => {
      table.style.width = '100%';
      table.style.tableLayout = 'fixed';
      table.style.marginTop = '20px';
      table.style.marginBottom = '10px';
    })

    let i = 0;
    customParagraphs.forEach((p: HTMLDivElement) => {
      i++;
      p.innerHTML = `&sect;${i}<br> ${p.textContent}`;
      p.style.textAlign = 'center';
      p.style.fontSize = '12px';
      p.style.fontWeight = '700';
      p.style.marginTop = '25px';
      p.style.marginBottom = '25px';
    });

    let file: any = null;

    content.querySelectorAll('span.mention').forEach((node: any) => {
      this.unwrap(node)
    })

    await doc.html(content, {
      margin: [30, 30, 45, 30],
      autoPaging: 'text',
      windowWidth: doc.internal.pageSize.getWidth() - 60,
      width: doc.internal.pageSize.getWidth() - 60,
      fontFaces: [
        {
          family: 'Poppins',
          style: 'normal',
          weight: '400',
          stretch: 'normal',
          src: [{
            url: require('../../../assets/Poppins-Regular.ttf'), format: 'truetype'
          }],
        },
        {
          family: 'Poppins',
          style: 'italic',
          weight: '400',
          stretch: 'normal',
          src: [{
            url: require('../../../assets/Poppins-Italic.ttf'), format: 'truetype'
          }],
        },
        {
          family: 'Poppins',
          style: 'normal',
          weight: '700',
          stretch: 'normal',
          src: [{
            url: require('../../../assets/Poppins-Bold.ttf'), format: 'truetype'
          }],
        },
        {
          family: 'Poppins',
          style: 'italic',
          weight: '700',
          stretch: 'normal',
          src: [{
            url: require('../../../assets/Poppins-BoldItalic.ttf'), format: 'truetype'
          }],
        },
      ],
      callback: (doc) => {
        const pageCount = (doc.internal as any).getNumberOfPages();

        // For each page, print the page number and the total pages
        for (var i = 1; i <= pageCount; i++) {
          // Go to page i
          doc.setPage(i);
          //Print Page 1 of 4 for example
          doc.setFontSize(10);
          doc.text(String(i) + ' / ' + String(pageCount), ((doc as any).getPageWidth()) / 2, ((doc as any).getPageHeight()) - 30, {
            align: 'center',
            flags: {
              noBOM: false,
              autoencode: true
            }
          });
        }

        doc.save(`${this.package.name}.pdf`);
      }
    });

    return file;
  }
}

