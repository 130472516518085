var render = function () {
  var _vm$package, _vm$package2, _vm$package3, _vm$package3$price, _vm$package4, _vm$package4$price;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accept-package"
  }, [_c('GenproxPageHeading', {
    attrs: {
      "title": "Agreement",
      "description": !((_vm$package = _vm.package) !== null && _vm$package !== void 0 && _vm$package.active) ? 'Please read and accept our Agreement to proceed with your selected plan' : '',
      "breadcrumbsTitle": (_vm$package2 = _vm.package) === null || _vm$package2 === void 0 ? void 0 : _vm$package2.name
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('GenproxButton', {
          attrs: {
            "outline": true,
            "icon": _vm.ChevronLeftIcon
          },
          on: {
            "click": function click($event) {
              return _vm.$router.go(-1);
            }
          }
        }, [_vm._v("Back")])];
      },
      proxy: true
    }])
  }), !_vm.regulation ? [_c('sygni-loader', {
    attrs: {
      "isLoading": true
    }
  })] : [_c('div', {
    staticClass: "accept-package__inner"
  }, [_c('div', {
    staticClass: "accept-package__body"
  }, [_c('div', {
    staticClass: "accept-package__content-wrapper"
  }, [_c('div', {
    staticClass: "accept-package__content",
    domProps: {
      "innerHTML": _vm._s(_vm.regulation)
    }
  })]), !_vm.package.active ? _c('div', {
    staticClass: "accept-package__additionals"
  }, [_c('div', {
    staticClass: "accept-package__additionals-container"
  }, [_c('p', {
    staticClass: "accept-package__additionals-header"
  }, [_vm._v("Confirmation")]), _c('div', {
    staticClass: "accept-package__checkbox"
  }, [_c('sygni-checkbox', {
    staticClass: "secondary normalize",
    attrs: {
      "legacyStyling": false
    },
    model: {
      value: _vm.areRegulationsAccepted,
      callback: function callback($$v) {
        _vm.areRegulationsAccepted = $$v;
      },
      expression: "areRegulationsAccepted"
    }
  }, [_vm._v(" I confirm that I have read the contract and accept its terms and conditions and that I have the capacity to represent the legal entity or have been authorised by the management to enter into a legally binding contract. ")])], 1)])]) : _vm._e()])])], _c('GenproxFormBar', {
    attrs: {
      "header": "".concat(((_vm$package3 = _vm.package) === null || _vm$package3 === void 0 ? void 0 : (_vm$package3$price = _vm$package3.price) === null || _vm$package3$price === void 0 ? void 0 : _vm$package3$price.value) | _vm.thousandSeparator, " ").concat((_vm$package4 = _vm.package) === null || _vm$package4 === void 0 ? void 0 : (_vm$package4$price = _vm$package4.price) === null || _vm$package4$price === void 0 ? void 0 : _vm$package4$price.currency),
      "description": _vm.paymentPlan
    },
    scopedSlots: _vm._u([{
      key: "actionsRight",
      fn: function fn() {
        var _vm$package5, _vm$package6, _vm$package7, _vm$package8, _vm$package9;

        return [_vm.packageId ? _c('GenproxButton', {
          attrs: {
            "outline": true
          },
          on: {
            "click": _vm.generatePdf
          }
        }, [_vm._v("Download PDF")]) : _vm._e(), (_vm$package5 = _vm.package) !== null && _vm$package5 !== void 0 && _vm$package5.active && ((_vm$package6 = _vm.package) === null || _vm$package6 === void 0 ? void 0 : _vm$package6.name) === 'Wezwanie KNF ws. ESG w ASI' ? _c('GenproxButton', {
          on: {
            "click": _vm.goToInsight
          }
        }, [_vm._v("Go to product")]) : _vm._e(), ((_vm$package7 = _vm.package) === null || _vm$package7 === void 0 ? void 0 : _vm$package7.name) === 'Regulatory filing (KNF Reporting)' ? _c('GenproxButton', {
          attrs: {
            "isDisabled": !((_vm$package8 = _vm.package) !== null && _vm$package8 !== void 0 && _vm$package8.active)
          },
          on: {
            "click": _vm.goToRegReporting
          }
        }, [_vm._v("Go to Reg Reporting")]) : _vm._e(), !((_vm$package9 = _vm.package) !== null && _vm$package9 !== void 0 && _vm$package9.active) ? _c('GenproxButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: !_vm.areRegulationsAccepted ? 'You need to accept the agreement at the bottom before continuing.' : '',
            expression: "!areRegulationsAccepted ? 'You need to accept the agreement at the bottom before continuing.' : ''",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "gn-form-bar__btn",
          attrs: {
            "icon": _vm.CheckCircleIcon,
            "variant": "success",
            "isDisabled": !_vm.areRegulationsAccepted
          },
          on: {
            "click": function click($event) {
              return _vm.accept();
            }
          }
        }, [_vm._v(" Accept ")]) : _c('GenproxButton', {
          staticClass: "gn-form-bar__btn",
          attrs: {
            "variant": "success",
            "outline": true,
            "icon": _vm.CheckCircleIcon
          }
        }, [_vm._v(" Active ")])];
      },
      proxy: true
    }])
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }